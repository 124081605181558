const timelineArray = [
  ["09", "國父於廣州大沙頭成立航空局，直隸於大元帥府，朱卓文為首任局長。"],
  ["11", "航空局增設飛機製造廠。"],
  ["12", "廣州飛機製造廠完成第一架自製軍事飛機－「樂士文」號。"],
  [
    "12.09.20",
    "航空局長楊仙逸先生因飛機裝彈爆炸身亡，此後政府訂定每年9月20日為航空紀念日。"
  ],
  ["14", "航空局組織變更，幕僚單位改為軍事、總務、航政等3處。"],
  [
    "15",
    "國民革命軍北伐，所屬航空隊進抵武漢後，航空局改組為「國民革命軍總司令部航空處」，由張靜愚任處長。"
  ],
  ["16.05", "國民政府奠都南京成立航空處，飛機總隊改番號為飛機第一、二隊。"],
  ["16.09", "航空處改隸於軍事委員會，並成立航空司令部便於指揮作戰。"],
  [
    "17.11.01",
    "航空處改組為「航空署」，隸屬軍政部，並授權經管全國軍、民用航空事務，第一任署長為熊斌。"
  ],
  ["18.06.07", "奉改組為航空班。"],
  ["20.07.01", "航空班改組為「軍政部航空學校」。"],
  ["20.12.28", "奉令遷校至杭州筧橋。"],
  ["21.08", "軍政部航空署劃歸軍事委員會指揮。"],
  [
    "21.09.01",
    "軍政部航空學校正式擴建為「中央航空學校」，由軍事委員會委員長蔣中正兼任校長。"
  ],
  ["22.02.10", "為求發揮現代化組織功能，制訂空軍官制。"],
  [
    "22.08.17",
    "為統一軍令，航空署改隸軍事委員會。航空隊則從原先的4隊，增編轟炸、驅逐及偵察等3隊，合計7隊。"
  ],
  [
    "23.03",
    "航空署遷至南昌，5月改組為「航空委員會」，航空隊亦擴編為8隊，由蔣中正先生擔任委員長。"
  ],
  ["25.01", "航空委員會遷回南京。"],
  [
    "26.08.14",
    "日本海軍鹿屋航空隊襲擊杭州筧橋機場，空軍第四大隊大隊長高志航率領霍克Ⅲ型機群，擊落日軍九六式轟炸機，為全面抗戰以來首次空戰大捷。"
  ],
  ["26.11", "航空委員會自南京遷移至漢口。"],
  ["27.02.23", "中蘇空軍空襲日本殖民下的臺灣松山機場。"],
  ["27.03", "因應抗戰情勢，航空委員會自漢口遷至衡陽，再遷貴陽。"],
  [
    "27.05.20",
    "首度空襲日本本土：派遣馬丁B-10型轟炸機兩架在長崎、佐賀等地投下20萬份「告日本民眾書」，號稱「人道遠征」。"
  ],
  ["28.01", "航空委員會由貴陽遷至成都。"],
  [
    "29.09.13",
    "壁山空戰，日軍派遣零式戰鬥機，性能遠超蘇聯援軍之I-16戰機，我方被擊落13架，陣亡10人。"
  ],
  ["30.04", "航空委員會移至重慶辦公，另在成都設空軍總指揮部與軍政廳。"],
  ["32.06.06", "梁山空戰，周志開上尉擊落3架日機，獲空軍首座青天白日勳章。"],
  ["32.11.25", "華美空軍奇襲日軍新竹機場，摧毀多架日機。"],
  [
    "34.09.09",
    "中國戰區受降典禮在南京中央陸軍軍官學校舉行，正式接受日軍投降，空軍第一路司令張廷孟上校為中國戰區空軍代表參與受降。"
  ],
  [
    "35.01",
    "航空委員會自重慶開始還都，全部人員、文卷，陸續抵達南京，至4月底全部遷移完畢。"
  ],
  ["35.06.01", "「航空委員會」改組為「空軍總司令部」。"],
  ["37.12", "奉令將人員及器材疏散至臺灣。"],
  ["38.04", "空軍總司令部自南京遷駐臺北市仁愛營區。"],
  ["40.06.01", "美軍駐臺軍事援助顧問團於空軍總司令部成立空軍顧問組。"],
  ["42.06", "空軍接收F-84戰鬥機，自此我空軍進入噴射時代。"],
  ["43", "換裝F-86戰鬥機。"],
  [
    "44.10.15",
    "孫嗣文上尉駕F-86戰鬥機擊落米格15一架，首創國軍換裝噴射機後空戰勝績。"
  ],
  ["45.07.21", "歐陽漪棻中尉以F-84擊落擊傷米格17各兩架，獲頒青天白日勳章。"],
  [
    "47.9.24",
    "「九二四溫州灣空戰」F-86戰鬥機創下9:0戰果，並首創以響尾蛇飛彈擊落敵機的紀錄。"
  ],
  ["47", "接收F-100超音速戰機與C-119運輸機。"],
  ["48", "接收RF-101偵察機。"],
  ["49", "接收F-104A/B戰鬥機，進入兩倍音速時代。"],
  ["54.11.28", "換裝F-5A/B戰鬥機。"],
  ["56.01.13", "一一三空戰，F-104擊落兩架米格19（殲6）。"],
  ["56", "接收S-2A反潛機。"],
  ["63.10.30", "F-5E戰機首架裝配完畢，舉辦出廠典禮，命名「中正號」。"],
  ["65", "空軍各作戰聯隊更銜為3位數番號。"],
  ["66", "F-86戰機除役。"],
  [
    "68",
    "應沙烏地阿拉伯王國所請，執行「大漠計畫」任務，實地援助北葉門建置F-5型戰機中隊十餘年。"
  ],
  ["73.", "F-100戰機除役。"],
  ["75", "接收C-130運輸機、S-70C直升機。"],
  ["77.12.10", "IDF型戰鬥機命名「經國號」。"],
  ["80", "首度招收女性飛行軍官。"],
  ["84", "接收E-2T空中預警機。"],
  ["86.04", "F-16型機首批接機典禮。"],
  ["86.06", "幻象2000-5型機接機典禮。"],
  ["86.12.19", "C-119運輸機除役。"],
  ["88.7.1", "「屏海專案」空軍反潛機大隊移編海軍。"],
  [
    "95.1.1",
    "飛彈司令部所屬防空飛彈部隊移編本軍，成立「空軍防空砲兵司令部」。"
  ],
  [
    "95.02.17",
    "空軍總司令部改制為「空軍司令部」，同年3月更銜為「空軍防空砲兵指揮部」。"
  ],
  ["97.01.21", "空軍C-130運輸機首降太平島。"],
  [
    "101.2.16",
    "空軍防空砲兵指揮部移編國防部，成立「國防部參謀本部防空飛彈指揮部」。"
  ],
  ["101.7.10", "EC-225型直升機成軍。"],
  ["101.10.05", "空軍司令部遷入大直忠勇營區，落成啟用。"],
  ["102.7.1", "海軍航指部定翼機移編本軍，新增「反潛作戰大隊」。"],
  ["105.12.30", "「空軍航空科技研究發展中心」編成。"],
  ["106.2.7", "「國造新式高教機」正式簽約啟動。"],
  [
    "106.3.1",
    "為利飛彈部整體發展運用，國防部參謀本部防空飛彈指揮部移編空軍，更銜為「空軍防空飛彈指揮部」。"
  ],
  [
    "106.9.1",
    "空軍防空飛彈指揮部移編空軍防空砲兵指揮部，更銜為「空軍防空暨飛彈指揮部」。"
  ],
  ["106.12.1", "P-3C反潛巡邏機成軍與S-2T型機除役，各飛行聯隊番號更銜。"],
  ["106.12.20", "空軍救護隊首批接收「UH-60M黑鷹直升機」。"],
  ["108.9.24", "首架國造新式高教機「勇鷹號」出廠。"]
];

const timelineData = `
09	國父於廣州大沙頭成立航空局，直隸於大元帥府，朱卓文為首任局長。
11	航空局增設飛機製造廠。
12　廣州飛機製造廠完成第一架自製軍事飛機－「樂士文」號。
12.09.20　航空局長楊仙逸先生因飛機裝彈爆炸身亡，此後政府訂定每年9月20日為航空紀念日。
14	航空局組織變更，幕僚單位改為軍事、總務、航政等3處。
15　國民革命軍北伐，所屬航空隊進抵武漢後，航空局改組為「國民革命軍總司令部航空處」，由張靜愚任處長。
16.05國民政府奠都南京成立航空處，飛機總隊改番號為飛機第一、二隊。
16.09　航空處改隸於軍事委員會，並成立航空司令部便於指揮作戰。
17.11.01　航空處改組為「航空署」，隸屬軍政部，並授權經管全國軍、民用航空事務，第一任署長為熊斌。
18.06.07　奉改組為航空班。
20.07.01　航空班改組為「軍政部航空學校」。
20.12.28　奉令遷校至杭州筧橋。
21.08　軍政部航空署劃歸軍事委員會指揮。
21.09.01　軍政部航空學校正式擴建為「中央航空學校」，由軍事委員會委員長蔣中正兼任校長。
22.02.10　為求發揮現代化組織功能，制訂空軍官制。
22.08.17　為統一軍令，航空署改隸軍事委員會。航空隊則從原先的4隊，增編轟炸、驅逐及偵察等3隊，合計7隊。
23.03　航空署遷至南昌，5月改組為「航空委員會」，航空隊亦擴編為8隊，由蔣中正先生擔任委員長。
25.01　航空委員會遷回南京。
26.08.14　日本海軍鹿屋航空隊襲擊杭州筧橋機場，空軍第四大隊大隊長高志航率領霍克Ⅲ型機群，擊落日軍九六式轟炸機，為全面抗戰以來首次空戰大捷。
26.11　航空委員會自南京遷移至漢口。
27.02.23　中蘇空軍空襲日本殖民下的臺灣松山機場。
27.03　因應抗戰情勢，航空委員會自漢口遷至衡陽，再遷貴陽。
27.05.20　首度空襲日本本土：派遣馬丁B-10型轟炸機兩架在長崎、佐賀等地投下20萬份「告日本民眾書」，號稱「人道遠征」。
28.01　航空委員會由貴陽遷至成都。
29.09.13　壁山空戰，日軍派遣零式戰鬥機，性能遠超蘇聯援軍之I-16戰機，我方被擊落13架，陣亡10人。
30.04　航空委員會移至重慶辦公，另在成都設空軍總指揮部與軍政廳。
32.06.06　梁山空戰，周志開上尉擊落3架日機，獲空軍首座青天白日勳章。
32.11.25　華美空軍奇襲日軍新竹機場，摧毀多架日機。
34.09.09　中國戰區受降典禮在南京中央陸軍軍官學校舉行，正式接受日軍投降，空軍第一路司令張廷孟上校為中國戰區空軍代表參與受降。
35.01　航空委員會自重慶開始還都，全部人員、文卷，陸續抵達南京，至4月底全部遷移完畢。
35.06.01　「航空委員會」改組為「空軍總司令部」。
37.12　奉令將人員及器材疏散至臺灣。
38.04	空軍總司令部自南京遷駐臺北市仁愛營區。
40.06.01　美軍駐臺軍事援助顧問團於空軍總司令部成立空軍顧問組。
42.06　空軍接收F-84戰鬥機，自此我空軍進入噴射時代。
43　換裝F-86戰鬥機。
44.10.15	　孫嗣文上尉駕F-86戰鬥機擊落米格15一架，首創國軍換裝噴射機後空戰勝績。
45.07.21　歐陽漪棻中尉以F-84擊落擊傷米格17各兩架，獲頒青天白日勳章。
47.9.24　「九二四溫州灣空戰」F-86戰鬥機創下9:0戰果，並首創以響尾蛇飛彈擊落敵機的紀錄。
47　接收F-100超音速戰機與C-119運輸機。
48　接收RF-101偵察機。
49　接收F-104A/B戰鬥機，進入兩倍音速時代。
54.11.28　換裝F-5A/B戰鬥機。
56.01.13	一一三空戰，F-104擊落兩架米格19（殲6）。
56	接收S-2A反潛機。
63.10.30　F-5E戰機首架裝配完畢，舉辦出廠典禮，命名「中正號」。
65　空軍各作戰聯隊更銜為3位數番號。
66	F-86戰機除役。
68　應沙烏地阿拉伯王國所請，執行「大漠計畫」任務，實地援助北葉門建置F-5型戰機中隊十餘年。
73.　F-100戰機除役。
75	接收C-130運輸機、S-70C直升機。
77.12.10　IDF型戰鬥機命名「經國號」。
80	首度招收女性飛行軍官。
84	接收E-2T空中預警機。
86.04　F-16型機首批接機典禮。
86.06　幻象2000-5型機接機典禮。
86.12.19　C-119運輸機除役。
88.7.1　「屏海專案」空軍反潛機大隊移編海軍。
95.1.1  飛彈司令部所屬防空飛彈部隊移編本軍，成立「空軍防空砲兵司令部」。
95.02.17　空軍總司令部改制為「空軍司令部」，同年3月更銜為「空軍防空砲兵指揮部」。
97.01.21　空軍C-130運輸機首降太平島。
101.2.16 空軍防空砲兵指揮部移編國防部，成立「國防部參謀本部防空飛彈指揮部」。
101.7.10　EC-225型直升機成軍。
101.10.05　空軍司令部遷入大直忠勇營區，落成啟用。
102.7.1　海軍航指部定翼機移編本軍，新增「反潛作戰大隊」。
105.12.30　「空軍航空科技研究發展中心」編成。
106.2.7　「國造新式高教機」正式簽約啟動。
106.3.1　為利飛彈部整體發展運用，國防部參謀本部防空飛彈指揮部移編空軍，更銜為「空軍防空飛彈指揮部」。
106.9.1  空軍防空飛彈指揮部移編空軍防空砲兵指揮部，更銜為「空軍防空暨飛彈指揮部」。
106.12.1　P-3C反潛巡邏機成軍與S-2T型機除役，各飛行聯隊番號更銜。
106.12.20 空軍救護隊首批接收「UH-60M黑鷹直升機」。
108.9.24 首架國造新式高教機「勇鷹號」出廠。
`;

export { timelineData, timelineArray };
